import { gql } from '@apollo/client'

const FULL_VISITOR_TRANSACTION_FRAGMENT = gql`
  fragment FullVisitorTransactionFragment on visitor {
    id
    uuid
    unique_uuid
    type
    template_id
    custom_fields
    id_number
    created_at
    user_id
    comments
    first_name
    last_name
    gate_in_id
    version
    transaction_template {
      id
      name
      location_id
      transaction_template_type
      template
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    user {
      id
      first_name
      last_name
    }
  }
`

const FULL_VISITOR_INVENTORY_TRANSACTION_FRAGMENT = gql`
  fragment FullVisitorInventoryTransactionFragment on visitor_inventory {
    id
    uuid
    unique_uuid
    type
    template_id
    custom_fields
    id_number
    created_at
    user_id
    comments
    first_name
    last_name
    gate_in_id
    version
    transaction_template {
      id
      name
      location_id
      transaction_template_type
      template
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    user {
      id
      first_name
      last_name
    }
  }
`

const GET_VISITOR_TRANSACTIONS_QUERY = gql`
  query visitorsQuery(
    $limit: Int
    $offset: Int
    $orderBy: [visitor_order_by!]
    $where: visitor_bool_exp
  ) {
    visitorsAggregate: visitor_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    visitors: visitor(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...FullVisitorTransactionFragment
    }
  }
  ${FULL_VISITOR_TRANSACTION_FRAGMENT}
`

const GET_VISITOR_TRANSACTION = gql`
  query visitor($id: Int!) {
    visitor: visitor_by_pk(id: $id) {
      ...FullVisitorTransactionFragment
    }
  }
  ${FULL_VISITOR_TRANSACTION_FRAGMENT}
`

const UPDATE_VISITOR_TRANSACTION = gql`
  mutation updateVisitorTransaction($visitor: visitor_utrx_input!) {
    visitor: update_visitor_transaction_actions(visitor: $visitor) {
      id
      unique_uuid
    }
  }
`

const GET_VISITOR_INVENTORY_TRANSACTIONS_QUERY = gql`
  query visitorsInventoryQuery(
    $limit: Int
    $offset: Int
    $orderBy: [visitor_inventory_order_by!]
    $where: visitor_inventory_bool_exp
  ) {
    visitorsAggregate: visitor_inventory_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    visitors: visitor_inventory(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...FullVisitorInventoryTransactionFragment
    }
  }
  ${FULL_VISITOR_INVENTORY_TRANSACTION_FRAGMENT}
`

export default {
  GET_VISITOR_TRANSACTIONS_QUERY,
  GET_VISITOR_TRANSACTION,
  UPDATE_VISITOR_TRANSACTION,
  GET_VISITOR_INVENTORY_TRANSACTIONS_QUERY
}

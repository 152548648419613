import { constants } from 'config'

const {
  ENUM_DATA: {
    USER_ROLES: {
      ADMIN,
      TENANT_ADMIN,
      MANAGER,
      SUPERVISOR,
      OFFICE_CLERK,
      ORGANIZATION_VIEWER
    }
  }
} = constants

const routes = {
  users: {
    id: 1,
    name: 'users',
    icon: 'people',
    path: '/users',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER, SUPERVISOR]
  },
  transactions: {
    id: 2,
    name: 'transactions',
    icon: 'input',
    path: '/transactions',
    allowedRoles: [
      ADMIN,
      TENANT_ADMIN,
      MANAGER,
      SUPERVISOR,
      OFFICE_CLERK,
      ORGANIZATION_VIEWER
    ]
  },
  edi: {
    id: 3,
    name: 'edi',
    icon: 'compare_arrows',
    path: '/edi',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER]
  },
  organizations: {
    id: 4,
    name: 'organizations',
    icon: 'business',
    path: '/organizations',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER, SUPERVISOR]
  },
  locations: {
    id: 5,
    name: 'locations',
    icon: 'location_on',
    path: '/locations',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER, SUPERVISOR]
  },
  inventory: {
    id: 6,
    name: 'inventory',
    icon: 'assignment',
    path: '/inventory',
    allowedRoles: [
      ADMIN,
      TENANT_ADMIN,
      MANAGER,
      SUPERVISOR,
      OFFICE_CLERK,
      ORGANIZATION_VIEWER
    ]
  },
  cameras: {
    id: 7,
    name: 'cameras',
    icon: 'camera_alt',
    path: '/cameras',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER]
  },
  releases: {
    id: 8,
    name: 'releases',
    icon: 'lock',
    path: '/releases',
    allowedRoles: [
      ADMIN,
      TENANT_ADMIN,
      MANAGER,
      SUPERVISOR,
      OFFICE_CLERK,
      ORGANIZATION_VIEWER
    ]
  },
  templates: {
    id: 9,
    name: 'templates',
    icon: 'build',
    path: '/templates',
    allowedRoles: [ADMIN, TENANT_ADMIN]
  },
  visitors: {
    id: 10,
    name: 'visitors',
    icon: 'transfer_within_a_station',
    path: '/visitors',
    allowedRoles: [ADMIN, TENANT_ADMIN, MANAGER, SUPERVISOR, OFFICE_CLERK]
  }
}

export default routes
// TransferWithinAStation

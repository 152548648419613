import { constants } from 'config'

const {
  ENUM_DATA: {
    EDI_COMPANIES: { CODECO }
  }
} = constants

const createFormat = ({
  name,
  client_id,
  client_code,
  locations,
  liner_codes,
  seal_codes
}) => {
  return {
    variables: {
      object: {
        name,
        client_id,
        company: CODECO,
        template: {
          client_code,
          locations,
          liner_codes,
          seal_codes
        }
      }
    }
  }
}

const formatFormValues = (
  initialValues,
  {
    id,
    name,
    is_enable,
    client_id,
    template: { client_code, locations, liner_codes, seal_codes }
  }
) => {
  return {
    ...initialValues,
    id,
    name,
    is_enable,
    client_id,
    client_code,
    locations,
    liner_codes,
    seal_codes
  }
}

const updateFormat = ({
  id,
  name,
  is_enable,
  client_id,
  client_code,
  locations,
  liner_codes,
  seal_codes
}) => {
  return {
    variables: {
      id,
      name,
      is_enable,
      client_id,
      template: {
        client_code,
        locations,
        liner_codes,
        seal_codes
      }
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  formatFormValues,
  updateFormat,
  deleteFormat
}

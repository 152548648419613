import createMaersk from './create/maersk'
import updateMaersk from './update/maersk'
import createKingOcean from './create/kingOcean'
import updateKingOcean from './update/kingOcean'
import createCodeco from './create/codeco'
import updateCodeco from './update/codeco'
import createUniversal from './create/universal'
import updateUniversal from './update/universal'

const schemas = {
  createMaersk,
  updateMaersk,
  createKingOcean,
  updateKingOcean,
  createCodeco,
  updateCodeco,
  createUniversal,
  updateUniversal
}

export default schemas

import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  is_enable: '',
  name: '',
  client_id: '',
  client_code: '',
  locations: [
    {
      location_id: '',
      location_code: '',
      port_name: ''
    }
  ],
  liner_codes: [
    {
      codeco_liner_code_id: '',
      smartgate_liner_code_id: ''
    }
  ],
  seal_codes: [
    {
      codeco_seal_code_id: '',
      smartgate_seal_code: ''
    }
  ]
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  is_enable: yup.boolean().required(validations.requiredField.code),
  client_id: yup.string().required(validations.requiredField.code),
  client_code: yup.string().required(validations.requiredField.code),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        location_id: yup.string().required(validations.requiredField.code),
        location_code: yup.string().required(validations.requiredField.code),
        port_name: yup.string().required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  liner_codes: yup
    .array()
    .of(
      yup.object().shape({
        codeco_liner_code_id: yup
          .string()
          .required(validations.requiredField.code),
        smartgate_liner_code_id: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  seal_codes: yup
    .array()
    .of(
      yup.object().shape({
        codeco_seal_code_id: yup
          .string()
          .required(validations.requiredField.code),
        smartgate_seal_code: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}

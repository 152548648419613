import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

const constants = {
  DEFAULT_TIMEZONE: moment.tz.guess(),
  DEFAULT_CSV_FILE_NAME: 'transactions',
  MAX_IMAGE_QUALITY: 0.35,
  ACCESS_TOKEN_FIELD: 'accessToken',
  LOCAL_STORAGE_KEYS: {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TRANSACTION: 'TRANSACTION',
    I18N: 'i18nextLng',
    TABLE_PREFERENCES: 'TABLE_PREFERENCES',
    TRANSACTION_TABLE_PREFERENCES: 'TRANSACTION_TABLE_PREFERENCES',
    INVENTORY_TABLE_PREFERENCES: 'INVENTORY_TABLE_PREFERENCES',
    CAMERA_TABLE_PREFERENCES: 'CAMERA_TABLE_PREFERENCES',
    EDI_TABLE_PREFERENCES: 'EDI_TABLE_PREFERENCES',
    LOCATION_TABLE_PREFERENCES: 'LOCATION_TABLE_PREFERENCES',
    ORGANIZATION_TABLE_PREFERENCES: 'ORGANIZATION_TABLE_PREFERENCES',
    USER_TABLE_PREFERENCES: 'USER_TABLE_PREFERENCES',
    RELEASE_TABLE_PREFERENCES: 'RELEASE_TABLE_PREFERENCES',
    TEMPLATE_TABLE_PREFERENCES: 'TEMPLATE_TABLE_PREFERENCES',
    VISITOR_TABLE_PREFERENCES: 'VISITOR_TABLE_PREFERENCES',
    THEME_TYPE: 'THEME_TYPE',
    CHARTS_PREFERENCES: 'CHARTS_PREFERENCES'
  },
  ENUM_DATA: {
    DAMAGE_TYPES: {
      DIRTY: 'DIRTY',
      HOLE: 'HOLE',
      FLOOR_DAMAGE: 'FLOOR_DAMAGE',
      SCRATCHED_FLOOR: 'SCRATCHED_FLOOR',
      RUST: 'RUST',
      DOOR_SEAL_DAMAGE: 'DOOR_SEAL_DAMAGE',
      WALL_DENT: 'WALL_DENT',
      CEILING_DENT: 'CEILING_DENT',
      STRUCTURAL_DAMAGE: 'STRUCTURAL_DAMAGE',
      CORNER_POST_DAMAGE: 'CORNER_POST_DAMAGE',
      SIDE_RAIL_DAMAGE: 'SIDE_RAIL_DAMAGE',
      CORNER_CASTING_DAMAGE: 'CORNER_CASTING_DAMAGE',
      DOOR_BARS_HANDLES: 'DOOR_BARS_HANDLES',
      SEAL_BLOCK_DAMAGE: 'SEAL_BLOCK_DAMAGE',
      PAINT: 'PAINT',
      ODOR: 'ODOR',
      CABLE_MISSING: 'CABLE_MISSING',
      PLUG_MISSING: 'PLUG_MISSING',
      REEFER_UNIT_PARTS_MISSING: 'REEFER_UNIT_PARTS_MISSING',
      OIL_PRESENT: 'OIL_PRESENT'
    },
    SEAL_TYPES: {
      CARRIER: 'CARRIER',
      SHIPPER: 'SHIPPER',
      VETERINARY_1: 'VETERINARY_1',
      VETERINARY_2: 'VETERINARY_2',
      CUSTOMS: 'CUSTOMS',
      TERMINAL: 'TERMINAL',
      VENTILATION: 'VENTILATION',
      FUMIGATION: 'FUMIGATION',
      OTHER_1: 'OTHER_1',
      OTHER_2: 'OTHER_2'
    },
    EDI_COMPANIES: {
      MAERSK: 'MAERSK',
      KING_OCEAN: 'KING_OCEAN',
      CODECO: 'CODECO',
      UNIVERSAL: 'UNIVERSAL'
    },
    BASE_TRANSACTION_TYPES: {
      GATE_IN: 'GATE_IN',
      GATE_OUT: 'GATE_OUT'
    },
    TRANSACTION_TYPES: {
      GATE_IN_EMPTY: 'GATE_IN_EMPTY',
      GATE_IN_LOADED: 'GATE_IN_LOADED',
      GATE_OUT_EMPTY: 'GATE_OUT_EMPTY',
      GATE_OUT_LOADED: 'GATE_OUT_LOADED'
    },
    INVENTORY_TRANSACTION_TYPES: {
      GATE_IN_EMPTY: 'GATE_IN_EMPTY',
      GATE_IN_LOADED: 'GATE_IN_LOADED'
    },
    TRANSACTION_TRANSPORTATION_TYPES: {
      EXPORT: 'EXPORT',
      IMPORT: 'IMPORT'
    },
    CONTAINER_STATUSES: {
      EMPTY: 'EMPTY',
      LOADED: 'LOADED'
    },
    PHOTO_TYPES: {
      CONTAINER: 'CONTAINER',
      CONTAINER_EMPTY: 'CONTAINER_EMPTY',
      SEAL: 'SEAL',
      DRIVER: 'DRIVER',
      DRIVER_ID: 'DRIVER_ID',
      PLATE: 'PLATE',
      DAMAGE: 'DAMAGE',
      CONTAINER_CONDITION: 'CONTAINER_CONDITION',
      CUSTOM: 'CUSTOM',
      LOCATION_LOGO: 'LOCATION_LOGO'
    },
    USER_ROLES: {
      ADMIN: 'admin',
      TENANT_ADMIN: 'tenantAdmin',
      MANAGER: 'manager',
      SUPERVISOR: 'supervisor',
      ORGANIZATION_VIEWER: 'organizationViewer',
      OFFICE_CLERK: 'officeClerk',
      GATE_AGENT: 'gateAgent'
    },
    EDI_MESSAGE_STATUSES: {
      SUCCESSFUL: 'SUCCESSFUL',
      FAILED: 'FAILED',
      WITHOUT_TEMPLATE: 'WITHOUT_TEMPLATE',
      WITHOUT_STATUS: 'WITHOUT_STATUS'
    },
    TEMPLATE_TYPES: {
      GATE_IN_EMPTY_IMPORT: 'GATE_IN_EMPTY_IMPORT',
      GATE_IN_EMPTY_EXPORT: 'GATE_IN_EMPTY_EXPORT',
      GATE_IN_LOADED_IMPORT: 'GATE_IN_LOADED_IMPORT',
      GATE_IN_LOADED_EXPORT: 'GATE_IN_LOADED_EXPORT',
      GATE_OUT_EMPTY_IMPORT: 'GATE_OUT_EMPTY_IMPORT',
      GATE_OUT_EMPTY_EXPORT: 'GATE_OUT_EMPTY_EXPORT',
      GATE_OUT_LOADED_IMPORT: 'GATE_OUT_LOADED_IMPORT',
      GATE_OUT_LOADED_EXPORT: 'GATE_OUT_LOADED_EXPORT'
    },
    EDI_UNIVERSAL_TEMPLATE_TYPES: {
      GATE_IN_EMPTY_IMPORT: 'GATE_IN_EMPTY_IMPORT',
      GATE_IN_EMPTY_EXPORT: 'GATE_IN_EMPTY_EXPORT',
      GATE_IN_LOADED_IMPORT: 'GATE_IN_LOADED_IMPORT',
      GATE_IN_LOADED_EXPORT: 'GATE_IN_LOADED_EXPORT',
      GATE_IN_EMPTY_IMPORT_UPDATE: 'GATE_IN_EMPTY_IMPORT_UPDATE',
      GATE_IN_EMPTY_EXPORT_UPDATE: 'GATE_IN_EMPTY_EXPORT_UPDATE',
      GATE_IN_LOADED_IMPORT_UPDATE: 'GATE_IN_LOADED_IMPORT_UPDATE',
      GATE_IN_LOADED_EXPORT_UPDATE: 'GATE_IN_LOADED_EXPORT_UPDATE',
      GATE_OUT_EMPTY_IMPORT: 'GATE_OUT_EMPTY_IMPORT',
      GATE_OUT_EMPTY_EXPORT: 'GATE_OUT_EMPTY_EXPORT',
      GATE_OUT_LOADED_IMPORT: 'GATE_OUT_LOADED_IMPORT',
      GATE_OUT_LOADED_EXPORT: 'GATE_OUT_LOADED_EXPORT',
      GATE_OUT_EMPTY_IMPORT_UPDATE: 'GATE_OUT_EMPTY_IMPORT_UPDATE',
      GATE_OUT_EMPTY_EXPORT_UPDATE: 'GATE_OUT_EMPTY_EXPORT_UPDATE',
      GATE_OUT_LOADED_IMPORT_UPDATE: 'GATE_OUT_LOADED_IMPORT_UPDATE',
      GATE_OUT_LOADED_EXPORT_UPDATE: 'GATE_OUT_LOADED_EXPORT_UPDATE'
    },
    VISITOR_TEMPLATE_TYPES: {
      VISITOR_GATE_IN: 'VISITOR_GATE_IN',
      VISITOR_GATE_OUT: 'VISITOR_GATE_OUT'
    },
    TRANSACTION_TEMPLATE_TYPES: {
      GATE_IN_EMPTY_IMPORT: 'GATE_IN_EMPTY_IMPORT',
      GATE_IN_EMPTY_EXPORT: 'GATE_IN_EMPTY_EXPORT',
      GATE_IN_LOADED_IMPORT: 'GATE_IN_LOADED_IMPORT',
      GATE_IN_LOADED_EXPORT: 'GATE_IN_LOADED_EXPORT',
      GATE_OUT_EMPTY_IMPORT: 'GATE_OUT_EMPTY_IMPORT',
      GATE_OUT_EMPTY_EXPORT: 'GATE_OUT_EMPTY_EXPORT',
      GATE_OUT_LOADED_IMPORT: 'GATE_OUT_LOADED_IMPORT',
      GATE_OUT_LOADED_EXPORT: 'GATE_OUT_LOADED_EXPORT',
      VISITOR_GATE_IN: 'VISITOR_GATE_IN',
      VISITOR_GATE_OUT: 'VISITOR_GATE_OUT'
    },
    LOCATION_TYPES: {
      PORT: 'PORT',
      STORAGE_INLAND_NEAR_PORT: 'STORAGE_INLAND_NEAR_PORT',
      STORAGE_INLAND: 'STORAGE_INLAND',
      STORAGE_RAIL: 'STORAGE_RAIL',
      WAREHOUSE: 'WAREHOUSE'
    },
    LOCATION_STATUS: {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
      SUSPENDED: 'SUSPENDED',
      TERMINATED: 'TERMINATED'
    }
  },
  PHOTO_KEYS: {
    PHOTO: 'photo',
    CONTAINER_PHOTO: 'container_photo'
  },
  FIELD_TYPES: {
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    SELECT: 'SELECT',
    PHOTO: 'PHOTO',
    MULTI_SELECT: 'MULTI_SELECT'
  },
  DOOR_FIELDS: {
    ID: 'id',
    UUID: 'uuid',
    CONTAINER_NUMBER: 'container_number',
    TRANSPORTATION_TYPE: 'transportation_type',
    CONTAINER_STATUS: 'container_status',
    CLIENT_ID: 'client_id',
    TYPE: 'type',
    CONSECUTIVE_FOLIO_NUMBER: 'consecutive_folio_number',
    CREATED_BY: 'createdBy',
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at'
  },
  VISITOR_DOOR_FIELDS: {
    ID: 'id',
    UUID: 'uuid',
    ID_NUMBER: 'id_number',
    TYPE: 'type',
    CREATED_BY: 'createdBy',
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at'
  },
  VISITOR_PHOTO_FIELDS: {},
  VISITOR_DEFAULT_FIELDS: {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    COMMENTS: 'comments'
  },
  DEFAULT_FIELDS: {
    CONTAINER_ISO_TYPE: 'container_iso_type',
    MGW_KILOGRAMS: 'mgw_kilograms',
    MAX_PAYLOAD_KILOGRAMS: 'max_payload_kilograms',
    TARE_KILOGRAMS: 'tare_kilograms',
    CARGO_WEIGHT_KILOGRAMS: 'cargo_weight_kilograms',
    VOLUME_CBM: 'volume_cbm',
    CONTAINER_YEAR: 'container_year',
    CONTAINER_EMPTY_PHOTO: 'container_empty_photo',
    SEAL_PHOTOS: 'seal_photos',
    LINER_ID: 'liner_id',
    SHIPPER_ID: 'shipper_id',
    CONSIGNEE_ID: 'consignee_id',
    BOOKING_NUMBER: 'booking_number',
    DRIVER_ID_PHOTO: 'driver_id_photo',
    DRIVER_NAME: 'driver_name',
    DRIVER_ID: 'driver_id',
    DRIVER_PHOTO: 'driver_photo',
    PLATE_PHOTO: 'plate_photo',
    PLATE_ID: 'plate_id',
    IS_DAMAGE: 'is_damage',
    DAMAGE_PHOTOS: 'damage_photos',
    CONTAINER_CONDITION: 'container_condition',
    STACK: 'stack',
    COMMENTS: 'comments',
    CONTAINER_CONDITION_PHOTOS: 'container_condition_photos',
    PTI_PASS: 'pti_pass',
    CLEAN: 'clean',
    SET_POINT_CELSIUS: 'set_point_celsius',
    RETURN_TEMPERATURE: 'return_temperature',
    SUPPLY_TEMPERATURE: 'supply_temperature',
    HUMIDITY_SET_POINT: 'humidity_set_point',
    HUMIDITY_ACTUAL: 'humidity_actual',
    VENTILATION: 'ventilation'
  },
  PHOTO_FIELDS: {
    CONTAINER_PHOTO: 'container_photo',
    CONTAINER_EMPTY_PHOTO: 'container_empty_photo',
    SEAL_PHOTOS: 'seal_photos',
    DRIVER_ID_PHOTO: 'driver_id_photo',
    DRIVER_PHOTO: 'driver_photo',
    PLATE_PHOTO: 'plate_photo',
    DAMAGE_PHOTOS: 'damage_photos',
    CONTAINER_CONDITION_PHOTOS: 'container_condition_photos'
  },
  REQUIRED_FIELDS: {
    CONTAINER_NUMBER: 'container_number',
    CONTAINER_PHOTO: 'container_photo',
    CLIENT_ID: 'client_id'
  },
  VISITOR_REQUIRED_FIELDS: {
    ID_NUMBER: 'id_number'
  },
  CHARTS: {
    MONTHLY_TRANSACTIONS: 'MONTHLY_TRANSACTIONS',
    INVENTORY: 'INVENTORY',
    GROUPED_BY_ISO: 'GROUPED_BY_ISO',
    DAYS_IN: 'DAYS_IN',
    EMPTY_CONTAINER_CONDITION: 'EMPTY_CONTAINER_CONDITION',
    LOADED_CONTAINER_CONDITION: 'LOADED_CONTAINER_CONDITION',
    RELEASES: 'RELEASES'
  }
}

export const SELECT_BOOL_OPTIONS = [
  {
    id: 0,
    value: true,
    name: 'yes'
  },
  {
    id: 1,
    value: false,
    name: 'no'
  }
]

export default constants

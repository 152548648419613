import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  name: '',
  type: '',
  location_id: '',
  steps: []
}

const schema = yup.object().shape({
  name: yup.string().required(validations.requiredField.code),
  type: yup.string().required(validations.requiredField.code),
  location_id: yup.string().required(validations.requiredField.code),
  steps: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          name: yup
            .string()
            .required(validations.requiredField.code)
            .matches(/^[aA-zZ\s]+$/, validations.onlyAlphabets.code),
          fields: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  name: yup.string().required(validations.requiredField.code),
                  type: yup.string().nullable(),
                  isDefault: yup
                    .boolean()
                    .required(validations.requiredField.code),
                  isRequired: yup
                    .boolean()
                    .required(validations.requiredField.code),
                  showInReport: yup
                    .boolean()
                    .required(validations.requiredField.code),
                  options: yup
                    .array()
                    .of(yup.string().required(validations.requiredField.code))
                    .nullable()
                })
                .required(validations.requiredField.code)
            )
            .required(validations.requiredField.code)
            .required(validations.requiredField.code)
        })
        .required(validations.requiredField.code)
    )
    .required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}

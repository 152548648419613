import { templateUtils } from 'utils'
import { constants } from 'config'

const {
  VISITOR_REQUIRED_FIELDS,
  FIELD_TYPES: { BOOLEAN }
} = constants

const formatFormValues = object => {
  const objectCopy = { ...object }
  const customFields = object?.custom_fields
  delete objectCopy.custom_fields

  const response = {
    ...objectCopy,
    comments: objectCopy.comments || '',
    transaction_id: objectCopy.id,
    ...customFields
  }

  return response
}

const formatUpdateValues = transaction => {
  const transactionTemplate = transaction?.transaction_template
  const template = transactionTemplate?.template
  const trx = {
    transaction_id: transaction?.id,
    uuid: transaction?.uuid
  }

  if (!template || !transaction) {
    return trx
  }

  Object.values(VISITOR_REQUIRED_FIELDS).forEach(name => {
    const value = transaction[name]

    trx[name] = value
  })

  Object.keys(template).forEach(key => {
    const step = template[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.forEach(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  return {
    variables: {
      visitor: trx
    }
  }
}

export default {
  formatFormValues,
  formatUpdateValues
}

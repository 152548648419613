import { gql } from '@apollo/client'

const TEMPLATE_FRAGMENT = gql`
  fragment TemplateFragment on transaction_template {
    id
    name
    transaction_template_type
    is_active
    is_default
    template
    location_id
    created_at
    is_release_required
    client_id
    client {
      id
      name
      trade_name
    }
    location {
      id
      name
    }
  }
`

const CREATE_TEMPLATE = gql`
  mutation createTemplate($template: template_input!) {
    template: create_template(template: $template) {
      id
    }
  }
`

const SET_TEMPLATE_INACTIVE = gql`
  mutation setTemplateInactive($id: Int!) {
    template: update_transaction_template_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: false }
    ) {
      id
    }
  }
`

const GET_TEMPLATES = gql`
  query templates(
    $limit: Int
    $offset: Int
    $orderBy: [transaction_template_order_by!]
    $where: transaction_template_bool_exp
  ) {
    templatesAggregate: transaction_template_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    templates: transaction_template(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`

const GET_TEMPLATE = gql`
  query template($id: Int!) {
    template: transaction_template_by_pk(id: $id) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`

const GET_FORM_DATA = gql`
  query templateFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_VISITOR_FORM_DATA = gql`
  query visitorTemplateFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

export default {
  CREATE_TEMPLATE,
  SET_TEMPLATE_INACTIVE,
  GET_TEMPLATES,
  GET_TEMPLATE,
  GET_FORM_DATA,
  GET_VISITOR_FORM_DATA
}

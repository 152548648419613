import maersk from './maersk'
import kingOcean from './kingOcean'
import codeco from './codeco'
import universal from './universal'

export default {
  maersk,
  kingOcean,
  codeco,
  universal
}
